<template>
  <div class="text-left" v-if="package_detail">
    <overlay-page :visible="is_loading_customer">
      <b-row>
        <b-col sm="12" md="12" lg="8" xl="8" class="mt-3">
          <b-card v-if="!is_upgrade_package">
            <div class="p-2">
              <div id="information-detail">
                <h4>{{ $t('subscription.checkout.personal_info') }}</h4>
                <b-row>
                  <b-col sm="12" md="12" lg="12" xl="12">
                    <div class="mt-2">
                      <select-component :options="juristic_type_list"
                                        required
                                        :label-select="$t('subscription.checkout.juristic_type')"
                                        :disabled-type="is_used_promo || customer_verify_status === 'verified'"
                                        v-model="$v.form.juristic_type.$model"
                                        @change="changeJuristic"
                                        :state="validateState($v.form.juristic_type)"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" :lg="form.juristic_type === 'juristic' ? 12 : 6"
                         :xl="form.juristic_type === 'juristic' ? 12 : 6">
                    <div class="mt-2">
                      <input-component
                          v-if="form.juristic_type === 'personal'"
                          :label-input="$t('subscription.checkout.first_name')"
                          required
                          @keypress="checkIsOnlyTHEnglish"
                          :max="'50'"
                          v-model="$v.form.first_name.$model"
                          :onlyTHEnglish="true"
                          :require-label="validateState($v.form.first_name) !== null && !$v.form.first_name.required  ? $t('common.requiredField') : ''"
                          :state="validateState($v.form.first_name)"
                          :disabled-type="customer_verify_status === 'verified'"
                      />
                      <input-component
                          v-else
                          :label-input="$t('subscription.checkout.company_name')"
                          required
                          @keypress="checkIsOnlyTHEnglishSpaceNumber"
                          :max="''"
                          v-model="$v.form.first_name.$model"
                          :onlyTHEnglish="false"
                          :require-label="validateState($v.form.first_name) !== null && !$v.form.first_name.required  ? $t('common.requiredField') : ''"
                          :state="validateState($v.form.first_name)"
                          :disabled-type="customer_verify_status === 'verified'"
                      />
                    </div>
                  </b-col>
                  <b-col sm="12" md="12" lg="6" xl="6" v-if="form.juristic_type === 'personal'">
                    <div class="mt-2">
                      <input-component :label-input="$t('subscription.checkout.last_name')"
                                       required
                                       max="50"
                                       :onlyTHEnglish="true"
                                       @keypress="checkIsOnlyTHEnglish"
                                       v-model="$v.form.last_name.$model"
                                       :require-label="validateState($v.form.last_name) !== null && !$v.form.last_name.required  ? $t('common.requiredField') : ''"
                                       :state="validateState($v.form.last_name)"
                                       :disabled-type="customer_verify_status === 'verified'"
                      />
                    </div>
                  </b-col>
                </b-row>
                <div v-if="form.juristic_type === 'personal'">
                  <small class="text-gray">{{ $t('subscription.checkout.remark_name') }}</small>
                </div>
                <b-row>
                  <b-col sm="12" md="12" lg="6" xl="6">
                    <div class="mt-2">
                      <input-component :label-input="$t('subscription.checkout.phone')"
                                       required
                                       v-model="$v.form.phone.$model"
                                       @keypress="chkNumber"
                                       max="11"
                                       :require-label="validateState($v.form.phone) !== null && !$v.form.phone.required ? $t('common.requiredField') : ( checkTel(telState, form.juristic_type) ? telInvalid : '')"
                                       :state="validateState($v.form.phone) && !checkTel(telState, form.juristic_type)"
                                       :disabled-type="customer_verify_status === 'verified'"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="mt-2">
                      <input-component
                          :label-input="form.juristic_type === 'personal' ? $t('subscription.checkout.card_id') :$t('subscription.checkout.tax_id')"
                          v-model="$v.form.tax_number.$model"
                          @keypress="chkNumber"
                          max="13"
                          required
                          :disabled-type="customer_verify_status === 'verified'"
                          :require-label="validateState($v.form.tax_number) !== null && !$v.form.tax_number.required  ? $t('common.requiredField') : ''"
                          :state="validateState($v.form.tax_number)"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" lg="6" xl="6">
                    <div class="mt-2">
                      <input-component :label-input="$t('subscription.checkout.email')"
                                       required
                                       :disabled-type="customer_verify_status === 'verified'"
                                       v-model="$v.form.email.$model"
                                       :require-label="validateState($v.form.email) !== null && !$v.form.email.required  ? $t('common.requiredField') : !$v.form.email.email ? $t('common.email_format') : ''"
                                       :state="validateState($v.form.email)"/>
                    </div>
                  </b-col>
                  <b-col v-if="form.juristic_type === 'juristic'" align-self="end" class="mb-4">
                    <b-form-checkbox
                        id="is-vat-3-percent"
                        v-model="form.include_withholding_tax"
                        :disabled="is_used_promo"
                    >
                      {{ $t('subscription.checkout.with_holding_tax') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div id="address-information" class="mt-2">
                <h4>{{ $t('subscription.checkout.address_info') }}</h4>
                <b-row>
                  <b-col>
                    <div class="mt-2">
                      <select-component :options="country_code_list" :label-select="$t('subscription.checkout.country')"
                                        required
                                        text-field="country"
                                        v-model="$v.form.address_info.country.$model"
                                        :require-label="validateState($v.form.address_info.country) !== null && !$v.form.address_info.country.required  ? $t('common.requiredField') : ''"
                                        :state="validateState($v.form.address_info.country)"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="mt-2">
                      <input-component :label-input="$t('subscription.checkout.address')"
                                       required
                                       v-model="$v.form.address_info.address.$model"
                                       :require-label="validateState($v.form.address_info.address) !== null && !$v.form.address_info.address.required  ? $t('common.requiredField') : ''"
                                       :state="validateState($v.form.address_info.address)"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-row class="mt-2 mb-1">
                      <b-col class="col-auto" style="padding-right: 5px!important;">
                        <label class="label-for-input address-label">
                          {{ $t('subscription.checkout.sub_district') }}
                        </label>
                      </b-col>
                      <b-col class="col-2 text-left" style="padding-left: 5px!important;">
                        <strong style="color: red">*</strong>
                      </b-col>
                    </b-row>
                    <addressinput-subdistrict v-model="$v.form.address_info.sub_district.$model"
                                              label=""
                                              :class="$v.form.address_info.sub_district.$error ? 'input-req': 'mb-3' "/>
                    <div class="mt-1">
                      <small v-if="$v.form.address_info.sub_district.$error"
                             style="color: #FC6C6B; margin-bottom: 1px;margin-top: 0px"> {{
                          $t('common.requiredField')
                        }}</small>
                    </div>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-row class="mt-2 mb-1">
                      <b-col class="col-auto" style="padding-right: 5px!important;">
                        <label class="label-for-input address-label">{{ $t('subscription.checkout.district') }} </label>
                      </b-col>
                      <b-col class="col-2 text-left" style="padding-left: 5px!important;">
                        <strong style="color: red">*</strong>
                      </b-col>
                    </b-row>
                    <addressinput-district v-model="$v.form.address_info.district.$model"
                                           label=""
                                           :class="$v.form.address_info.district.$error ? 'input-req': 'mb-3' "/>
                    <div class="mt-1">
                      <small v-if="$v.form.address_info.district.$error"
                             style="color: #FC6C6B; margin-bottom: 1px;margin-top: 0px">{{
                          $t('common.requiredField')
                        }}
                      </small>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-row class="mt-2 mb-1">
                      <b-col class="col-auto" style="padding-right: 5px!important;">
                        <label class="label-for-input address-label">{{ $t('subscription.checkout.province') }} </label>
                      </b-col>
                      <b-col class="col-2 text-left" style="padding-left: 5px!important;">
                        <strong style="color: red">*</strong>
                      </b-col>
                    </b-row>
                    <addressinput-province v-model="$v.form.address_info.province.$model"
                                           label=""
                                           :class="$v.form.address_info.province.$error ? 'input-req': 'mb-3' "/>
                    <div class="mt-1">
                      <small v-if="$v.form.address_info.province.$error"
                             style="color: #FC6C6B; margin-bottom: 1px;margin-top: 0px">{{
                          $t('common.requiredField')
                        }}
                      </small>
                    </div>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-row class="mt-2 mb-1">
                      <b-col class="col-auto" style="padding-right: 5px!important;">
                        <label class="label-for-input address-label">{{ $t('subscription.checkout.zip_code') }} </label>
                      </b-col>
                      <b-col class="col-2 text-left" style="padding-left: 5px!important;">
                        <strong style="color: red">*</strong>
                      </b-col>
                    </b-row>
                    <addressinput-zipcode v-model="$v.form.address_info.zipcode.$model"
                                          label="" :class="$v.form.address_info.zipcode.$error ? 'input-req': 'mb-3' "/>
                    <div class="mt-1">
                      <small v-if="$v.form.address_info.zipcode.$error"
                             style="color: #FC6C6B; margin-bottom: 1px;margin-top: 0px">{{
                          $t('common.requiredField')
                        }}
                      </small>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div id="footer-form" v-if="innerWidth > mobileWidth">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <b-button v-if="!channel_came_from" class="button-wizard" variant="outline"
                              @click="actionStep('prev', 1)">
                      <i class="fas fa-angle-left"/>
                      {{ $t('common.back') }}
                    </b-button>
                  </div>
                  <div>
                    <b-button class="button-wizard" variant="primary" @click.prevent="updateAddressInvoice()"
                              :disabled="is_loading_update || promotion_code !== '' && !is_used_promo || checkTel(telState, form.juristic_type) || $v.form.$invalid ">
                      {{ $t('common.next') }}
                      <b-spinner small v-if="is_loading_update" class="mt-1"/>
                      <i v-else class="fas fa-angle-right"/>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
          <b-card v-else>
            <div class="p-2">
              <div>
                <div class="box-upgrade-package">
                  <img v-if="$i18n.locale === 'th'" alt="upgrade-package"
                       src="@/assets/images/upgrade/img_Trcking2Pro@2x.webp"/>
                  <img v-else alt="upgrade-package"
                       src="@/assets/images/upgrade/img_Trcking2ProEN.webp"/>

                </div>
                <div class="mt-4">
                  <div>
                    <hr/>
                    <div class="text-bold">
                      {{ $t('subscription.checkout.conditions') }}
                    </div>
                    <div class="small">
                      {{ $t('subscription.checkout.conditions_customer') }}
                    </div>
                  </div>
                  <div class="font-size-10">
                    {{ $t('subscription.checkout.conditions_company') }}
                  </div>
                </div>
              </div>
              <div id="footer-form" v-if="innerWidth > mobileWidth" class="mt-4">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <b-button class="button-wizard" variant="outline" @click="prevActionFromUpgrade()">
                      <i class="fas fa-angle-left"/>
                      {{ $t('common.back') }}
                    </b-button>
                  </div>
                  <div>
                    <b-button class="button-wizard" variant="primary"
                              @click.prevent="confirmUpgradePackage()"
                              :disabled="is_loading_update || promotion_code !== '' && !is_used_promo || checkTel(telState, form.juristic_type) || $v.form.$invalid ">
                      {{ $t('common.next') }}
                      <b-spinner small v-if="is_loading_update" class="mt-1"/>
                      <i v-else class="fas fa-angle-right"/>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="12" lg="4" xl="4" class="mt-3">
          <b-row>
            <b-col cols="12">
              <b-card>
                <div id="promotion-code" class="p-2">
                  <h4>{{ $t('subscription.checkout.code_promotion') }}</h4>
                  <div class="mb-3">
                    <input-component-search :searchOptions="plan_options" v-model="promotion_code"
                                            :disabled-type="is_used_promo"/>
                  </div>
                  <div class="float-right">
                    <button type="button" v-if="!is_used_promo && promotion_code !== ''"
                            class="btn waves-effect waves-light btn-outline mr-2" @click="resetSearch">
                      {{ $t('common.resetSearch') }}
                    </button>
                    <b-button v-if="!is_used_promo" variant="primary"
                              :disabled="is_promo_code_loading || promotion_code === ''"
                              @click="usePromotionCode('promotion')">
                      {{ $t('common.used') }}
                      <b-spinner v-if="is_promo_code_loading" small/>
                    </b-button>
                    <b-button v-else variant="danger"
                              @click="cancelPromotionCode">
                      {{ $t('common.cancel') }}
                      <b-spinner v-if="is_cancel_promo_loading" small/>
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-card>
                <div id="order-summary" class="p-2">
                  <h4>{{ $t('subscription.checkout.order_summery') }}</h4>
                  <div class="pb-3 pt-1">
                    <div v-if="is_promo_code_loading" class="d-flex justify-content-center mt-3 text-primary">
                      <b-spinner small/>
                      <div class="ml-2">{{ $t('loading') }}</div>
                    </div>
                    <div v-else>
                      <b-row align-h="between" class="order-summary-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.package_name') }}</b-col>
                        <b-col class="text-right text-primary">{{ order_summary.package_name || '-' }}</b-col>
                      </b-row>
                      <b-row align-h="between" class="order-summary-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.package_price') }}</b-col>
                        <b-col class="text-right">{{ changeCurrency(order_summary.amount) || 0 }}</b-col>
                      </b-row>
                      <b-row v-if="order_summary.package_discount" align-h="between"
                             class="order-summary-info-sub font-12">
                        <b-col class="text-left">{{ $t('subscription.checkout.discount_package') }}</b-col>
                        <b-col class="text-right text-danger">
                          {{
                            order_summary.package_discount > 0 ? '-' + ' ' +
                                changeCurrency(order_summary.package_discount) :
                                changeCurrency(order_summary.package_discount)
                          }}
                        </b-col>
                      </b-row>
                      <b-row align-h="between" class="order-summary-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.credit_received') }}</b-col>
                        <b-col class="text-right">
                          {{
                            order_summary.package_credit ? changeTextToLocaleString(order_summary.package_credit) + ' ' +
                                $t('subscription.checkout.credit') : 0
                          }}
                        </b-col>
                      </b-row>
                      <div id="promotion-code" v-if="is_used_promo">
                        <b-row align-h="between" class="order-summary-info">
                          <b-col class="text-left">{{ $t('subscription.checkout.code_promotion') }}</b-col>
                        </b-row>
                        <b-row align-h="between" class="order-summary-info-sub">
                          <b-col class="text-left">{{ $t('subscription.checkout.discount') }}
                            {{
                              order_summary.promotion_detail.discount_type === 'price' ?
                                  checkPercentPromotion(order_summary.promotion_detail) : ''
                            }}
                          </b-col>
                          <b-col class="text-right text-danger">
                            {{
                              order_summary.promotion_detail.discount_type === 'price' ?
                                  checkAmountPromotion(order_summary.promotion_detail) : changeCurrency(0)
                            }}
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="order-summary-info-sub">
                          <b-col class="text-left">{{ $t('subscription.checkout.add_credit') }}
                            {{
                              order_summary.promotion_detail.discount_type === 'credit' ?
                                  checkPercentPromotion(order_summary.promotion_detail) : ''
                            }}
                          </b-col>
                          <b-col class="text-right credit-text">
                            {{
                              order_summary.promotion_detail.discount_type === 'credit' ?
                                  checkAmountPromotion(order_summary.promotion_detail) : 0 + ' ' +
                                  $t('subscription.checkout.credit')
                            }}
                          </b-col>
                        </b-row>
                      </div>
                      <b-row align-h="between" class="order-summary-info"
                             v-if="form.juristic_type === 'juristic' && form.include_withholding_tax">
                        <b-col class="text-left">{{ $t('subscription.checkout.with_holding_tax') }}</b-col>
                        <b-col class="text-right text-danger">
                          {{
                            changeCurrency(order_summary.include_withholding_tax) ? '-' + ' ' +
                                changeCurrency(order_summary.include_withholding_tax) : 0
                          }}
                        </b-col>
                      </b-row>
                      <b-row align-h="between" class="order-summary-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.vat') }}</b-col>
                        <b-col class="text-right">
                          {{ changeCurrency(order_summary.vat) ? '+' + ' ' + changeCurrency(order_summary.vat) : 0 }}
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <hr/>
                  <div v-if="!is_promo_code_loading">
                    <div class="pt-2">
                      <b-row align-h="between" class="order-total-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.net_amount') }}</b-col>
                        <b-col class="text-right">{{ changeCurrency(order_summary.total_price) }}</b-col>
                      </b-row>
                    </div>
                    <div class="pt-2">
                      <b-row align-h="between" class="order-total-info">
                        <b-col class="text-left">{{ $t('subscription.checkout.net_credit') }}</b-col>
                        <b-col class="text-right">
                          {{
                            netCredit(order_summary.package_credit, order_summary.net_credit) || 0
                          }}
                        </b-col>
                      </b-row>
                    </div>
                    <!--                    {{order_summary.total_tracking2pro}}-->
                    <div class="pt-2">
                      <b-row align-h="between" class="order-total-info" v-if="is_upgrade_package">
                        <b-col class="text-left d-flex">{{ $t('subscription.checkout.net_url_tracking2pro') }}
                          <div class="ml-2 text-danger">{{ $t('subscription.checkout.free') }}</div>
                        </b-col>
                        <b-col class="text-right">
                          {{ order_summary.total_tracking2pro?.toLocaleString() + ' ' + 'URL' }}
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
                <div id="footer-form" class="mt-3" v-if="innerWidth < mobileWidth">
                  <div class="d-flex justify-content-between mt-2">
                    <div>
                      <b-button v-if="!is_upgrade_package" class="button-wizard" variant="outline"
                                @click="actionStep('prev', 1)">
                        <i class="fas fa-angle-left"/>
                        {{ $t('common.back') }}
                      </b-button>
                      <b-button v-else class="button-wizard" variant="outline" @click="prevActionFromUpgrade()">
                        <i class="fas fa-angle-left"/>
                        {{ $t('common.back') }}
                      </b-button>
                    </div>
                    <div>
                      <b-button class="button-wizard" variant="primary" @click.prevent="updateAddressInvoice()"
                                v-if="!is_upgrade_package"
                                :disabled="is_loading_update || promotion_code !== '' && !is_used_promo || checkTel(telState, form.juristic_type) || $v.form.$invalid ">
                        {{ $t('common.next') }}
                        <b-spinner small v-if="is_loading_update" class="mt-1"/>
                        <i v-else class="fas fa-angle-right"/>
                      </b-button>
                      <b-button class="button-wizard" variant="primary"
                                @click.prevent="confirmUpgradePackage()"
                                v-else
                                :disabled="is_loading_update || promotion_code !== '' && !is_used_promo || checkTel(telState, form.juristic_type) || $v.form.$invalid ">
                        {{ $t('common.next') }}
                        <b-spinner small v-if="is_loading_update" class="mt-1"/>
                        <i v-else class="fas fa-angle-right"/>
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </overlay-page>
  </div>
</template>
<script>
import SelectComponent from "@/components/common/selectComponent.vue";
import InputComponent from "@/components/common/inputComponent.vue";
import subscriptionApi from "@/repository/subscriptionApi";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, requiredIf} from "vuelidate/lib/validators";
import masterData from '@/common/masterData.json'
import masterCountryCode from '@/common/masterCountryCode.json'
import authApi from "@/repository/authApi";
import OverlayPage from "@/components/common/overlayPage.vue";
import functionsCommon from "@/common/functions";
import Input from "@/views/smsAPI/commonAPI/input.vue";
import {mapState} from "vuex";
import inputComponentSearch from "@/components/common/inputComponentSearch.vue";
import profileApi from "@/repository/profileApi";

export default {
  name: "paymentDetail",
  components: {inputComponentSearch, Input, OverlayPage, InputComponent, SelectComponent},
  data() {
    return {
      form: {
        is_renew: false,
        juristic_type: 'personal',
        include_withholding_tax: false,
        first_name: '',
        last_name: '',
        phone: '',
        tax_number: '',
        email: '',
        address_info: {
          country: '',
          address: '',
          sub_district: '',
          district: '',
          province: '',
          zipcode: ''
        },
        amount: 0,
        payment_methods: '',
      },
      channel_came_from: '',
      is_loading_update: false,
      is_loading_customer: false,
      juristic_type_list: masterData.juristic_list,
      country_code_list: masterCountryCode.country_code,
      package_detail: {},
      package_custom: {},
      customer_id: null,
      order_summary: {
        package_id: null,
        package_name: '',
        amount: 0,
        currency: '',
        discount_percentage: null,
        discount_price: 0,
        total_include_discount: 0,
        vat: 0,
        withholding_tax: 0,
        include_withholding_tax: 0,
        total_price: 0,
        package_credit: 0,
        package_discount: 0,
        net_credit: null,
        promotion_detail: {
          discount_percentage: null,
          discount_type: '',
          is_percentage_discount: false,
          total_discount: 0
        },
        upgrade_detail: {
          is_premium_url: false,
          upgrade_discount: 0,
          upgrade_percent_discount: 0,
          upgrade_url_net: 0,
          upgrade_full_price: 0
        },
        total_url: 0,
        total_tracking2pro: 0
      },
      is_promo_code_loading: false,
      is_used_promo: false,
      is_cancel_promo_loading: false,
      subscription_type: null,
      plan_options: [],
      is_promotion_list: false,
      promotion_code: '',
      is_upgrade_package: false,
      is_upgrade_url: false,
      premium_url_plan_price: 0, // ful price
      premium_url_plan_net: 0,// discount,
      premium_url_plan_discount_percentage: 0,//percent
      verify_info_detail: {},
      customer_verify_status: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      juristic_type: {required},
      first_name: {required},
      last_name: {
        required: requiredIf(function () {
          return this.form.juristic_type === 'personal'
        })
      },
      phone: {required},
      tax_number: {
        required: required,
        maxLength: maxLength(13),
        minLength: minLength(7)
      },
      email: {required, email},
      address_info: {
        country: {required},
        address: {required},
        sub_district: {required},
        district: {required},
        province: {required},
        zipcode: {required},
      }
    },
    email: {required},
    address_info: {
      country: {required},
      address: {required},
      sub_district: {required},
      district: {required},
      province: {required},
      zipcode: {required},
    }
  },
  computed: {
    telState() {
      return functionsCommon.telStateCompany(this.form.phone)
    },
    telInvalid() {
      const data = functionsCommon.telInvalidCompany(this.form.phone)
      return this.$t(data)
    },
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    }),
  },
  destroyed() {
    this.$swal.close();
  },
  mounted() {
    this.subscription_type = this.$route.query.subscription
    this.channel_came_from = this.$route.query.channel
    this.setSelectedPackage();
  },
  methods: {
    setSelectedPackage() {
      this.package_detail = JSON.parse(sessionStorage.getItem('_pack'));
      if (_.isNil(this.package_detail)) {
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('common.invalid_entry'),
          text: this.$t('common.please_select_package'),
          showCancelButton: false,
          confirmButtonText: this.$t('button.confirm'),
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push(`/Subscriptions`).catch(() => {
            })
          }
        })
      } else {
        let with_holding = this.form.juristic_type === 'juristic' && this.form.include_withholding_tax ? 3 : 0
        let vat = 7
        this.getCustomerProfile()
        let count_vat = this.countVat(this.package_detail.price, this.package_detail?.price_discount, vat)
        let count_with_holding_tax = this.form.include_withholding_tax ? this.countVat(this.package_detail.price, this.package_detail?.price_discount, with_holding) : 0
        let credit = this.package_detail?.options?.sms_max || this.package_detail?.package_credit
        this.premium_url_plan_price = this.package_detail?.premium_url_plan_price || 0
        this.premium_url_plan_net = this.package_detail?.premium_url_plan_net || 0
        this.premium_url_plan_discount_percentage = this.package_detail?.premium_url_plan_discount_percentage || 0
        let total_price = this.totalPrice(this.package_detail?.price, this.package_detail?.price_discount, count_vat, count_with_holding_tax)
        this.order_summary = {
          ...this.order_summary,
          package_id: this.package_detail?._id,
          package_name: this.package_detail?.name,
          amount: this.package_detail?.price,
          currency: this.package_detail?.currency?.format || '฿{PRICE}',
          package_credit: this.totalCredits(credit),
          package_discount: this.package_detail?.price_discount,
          vat: count_vat,
          include_withholding_tax: count_with_holding_tax,
          total_price: total_price,
          total_url: this.package_detail?.options?.url_tracking ? this.package_detail?.options?.url_tracking : this.package_detail?.total_url ? this.package_detail?.total_url : 0,
          total_tracking2pro: this.package_detail?.options?.url_tracking ? this.package_detail?.options?.url_tracking : this.package_detail?.total_url ? this.package_detail?.total_url : 0
        }
      }
    },
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    prevActionFromUpgrade() {
      this.is_upgrade_package = false
      this.is_upgrade_url = false
      this.usePromotionCode()
    },
    actionStep(action, index) {
      const action_btn = {
        action: action,
        page_index: index || null,
        data: {
          plan_id: this.order_summary.package_id,
          discount_code: this.promotion_code,
          custom_credits: this.order_summary.package_credit,
          custom_price: this.order_summary.amount,
          is_custom: this.subscription_type === 'custom_package',
          is_juristic: this.form.juristic_type,
          is_upgrade_url: this.is_upgrade_url,
        }
      }
      this.$emit('set-active-step', action_btn)
    },
    getCustomerProfile() {
      this.is_loading_customer = true
      authApi.getCustomerProfile().then(resp => {
        let clone_deep = _.cloneDeep(this.form) // ใช้กรณีที่ใช้โค้ดโปรโมชั่นครั้งแรกแล้วยกเลิก
        const raw_data = _.get(resp, 'options', {})
        this.customer_verify_status = resp?.identity_verify_status
        if (resp?.identity_verify_status !== 'verified') {
          this.form = {
            ...this.form,
            juristic_type: raw_data?.is_juristic ? 'juristic' : 'personal',
            include_withholding_tax: raw_data?.include_withholding_tax,
            first_name: raw_data?.first_name || clone_deep.first_name,
            last_name: raw_data?.last_name || clone_deep.last_name,
            phone: raw_data?.phone || clone_deep.phone,
            tax_number: raw_data?.tax_number || clone_deep.tax_number,
            email: raw_data?.email || clone_deep.email,
            address_info: {
              country: raw_data?.country || clone_deep.address_info.country || 'th',
              address: raw_data?.address || clone_deep.address_info.address,
              sub_district: raw_data?.sub_district || clone_deep.address_info.sub_district,
              district: raw_data?.district || clone_deep.address_info.district,
              province: raw_data?.province || clone_deep.address_info.province,
              zipcode: raw_data?.zipcode || clone_deep.address_info.zipcode,
            },
            amount: this.package_detail?.price || 0,
          }
        } else {
          this.verifyInfoAccount(resp)
        }

        this.customer_id = resp._id
        this.getPromotionByPlanId()
      }).finally(() => {
        this.is_loading_customer = false
      })
    },
    updateAddressInvoice() {
      this.$v.form.$touch()
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.is_loading_update = true
      subscriptionApi.updateAddressInvoice(this.form, this.customer_id).then(resp => {
        if (resp) {
          if (this.package_detail?.premium_url_plan_id) {
            this.is_upgrade_package = true
          } else {
            this.is_upgrade_package = false
            this.actionStep('next')
          }
          this.getCustomerProfile()
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response.data.message)
      }).finally(() => {
        this.is_loading_update = false
      })
    },
    usePromotionCode(key) {
      if (key === 'promotion') {
        this.is_promo_code_loading = true
      }
      let map_data;
      if (this.subscription_type === 'custom_package') {
        map_data = {
          is_custom_plan: true,
          custom_detail: {
            price: this.package_detail.price,
            credit: this.package_detail.package_credit
          },
          plan_id: this.package_detail._id,
          discount_code: this.promotion_code,
          include_withholding_tax: this.form.include_withholding_tax,
          is_upgrade_url: this.is_upgrade_url
        }
      } else {
        map_data = {
          is_custom_plan: false,
          plan_id: this.package_detail._id,
          discount_code: this.promotion_code,
          include_withholding_tax: this.form.include_withholding_tax,
          is_upgrade_url: this.is_upgrade_url
        }
      }

      subscriptionApi.usePromotionCode(map_data).then(resp => {
        if (resp) {
          if (key === 'promotion') {
            this.is_used_promo = true
          }
          this.order_summary = {
            ...this.order_summary,
            discount_price: resp?.discount_price,
            total_include_discount: resp?.total_include_discount,
            vat: resp?.vat,
            include_withholding_tax: resp?.total_withholding_tax,
            total_price: resp?.total_price,
            package_credit: resp?.plan_credit,
            net_credit: resp?.total_credits,
            promotion_detail: {
              discount_percentage: resp?.promotion_detail?.discount_percentage,
              discount_type: resp?.promotion_detail?.discount_type,
              is_percentage_discount: resp?.promotion_detail?.is_percentage_discount,
              total_discount: resp?.promotion_detail?.total_discount,
            },
            upgrade_detail: {
              is_premium_url: resp?.upgrade_url_detail?.is_premium_url,
              upgrade_discount: resp?.upgrade_url_detail?.upgrade_discount,
              upgrade_percent_discount: resp?.upgrade_url_detail?.upgrade_percent_discount,
              upgrade_url_net: resp?.upgrade_url_detail?.upgrade_url_net,
              upgrade_full_price: resp?.upgrade_url_detail?.upgrade_full_price,
            },
            total_tracking2pro: resp?.total_url || 0
          }
        }
      }).catch(err => {
        this.is_used_promo = false
        alert.usePromotionCodeFailed(this, err.response.data.message)
      }).finally(() => {
        this.is_promo_code_loading = false
      })
    },
    changeCurrency(number) {
      return functionsCommon.addCurrencyToPrice(number, this.order_summary.currency)
    },
    countVat(price = 0, discount = 0, vat) {
      return functionsCommon.vat(price, discount, vat)
    },
    changeTextToLocaleString(text) {
      return Number(text)?.toLocaleString()
    },
    totalCredits(credit = 0, fee_credit = 0) {
      return Number(credit) + Number(fee_credit)
    },
    totalPrice(package_price = 0, package_discount = 0, vat = 0, holding_tax = 0) {
      let price = (Number(package_price) + Number(vat)) - Number(holding_tax)
      return Number(price) - Number(package_discount)
    },
    cancelPromotionCode() {
      this.is_used_promo = false
      this.order_summary.net_credit = null
      this.setSelectedPackage();
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    checkPercentPromotion(promotion) {
      if (promotion.is_percentage_discount) {
        return '(' + promotion.discount_percentage + '%' + ')'
      } else if (promotion.is_premium_url) {
        return '(' + promotion.upgrade_percent_discount + '%' + ')'
      }
    },
    checkAmountPromotion(promotion) {
      if (promotion.discount_type === 'price') {
        return promotion.total_discount > 0 ? '-' + ' ' + this.changeCurrency(promotion.total_discount) : this.changeCurrency(promotion.total_discount)
      } else if (promotion.is_premium_url) {
        return promotion.upgrade_discount ? '-' + ' ' + this.changeCurrency(promotion.upgrade_discount) : this.changeCurrency(0)
      } else {
        const credit = promotion.total_discount > 0 ? '+' + ' ' + this.changeTextToLocaleString(promotion.total_discount) : this.changeTextToLocaleString(promotion.total_discount)
        return credit + ' ' + this.$t('subscription.checkout.credit')
      }
    },
    netCredit(package_credit, total_credit) {
      const net_credit = total_credit ? total_credit : package_credit
      return this.changeTextToLocaleString(net_credit) + ' ' + this.$t('subscription.checkout.credit')
    },
    getPromotionByPlanId() {
      subscriptionApi.getPromotionByPlanId(this.package_detail._id).then(resp => {
        this.plan_options = _.filter(resp?.data, item => {
          return !!item.is_available && !item.is_secret
        })
      }).catch(err => {
        throw err
      })
    },
    resetSearch() {
      this.promotion_code = ''
    },
    confirmUpgradePackage() {
      this.is_upgrade_url = true
      this.actionStep('next')
    },
    checkIsOnlyTHEnglish(e) {
      return functionsCommon.isOnlyTHEnglish(e)
    },
    checkIsOnlyTHEnglishSpaceNumber(e) {
      return functionsCommon.isOnlyTHEnglishSpaceNumber(e)
    },
    verifyInfoAccount(profile_data,) {
      profileApi.getVerifyInfo().then(resp => {
        const address = _.get(resp, 'address', {})
        if (!!resp) {
          this.form = {
            ...this.form,
            juristic_type: resp?.customer_type,
            include_withholding_tax: profile_data?.include_withholding_tax,
            first_name: resp?.customer_type === 'personal' ? resp?.first_name || '' : resp?.company_name || '',
            last_name: resp?.last_name || '',
            phone: resp?.customer_type === 'personal' ? profile_data?.phone : resp?.company_phone || profile_data?.phone,
            tax_number: resp?.tax_id || '',
            email: profile_data?.email || '',
            address_info: {
              country: address?.country || 'th',
              address: address?.address || '',
              sub_district: address?.sub_district || '',
              district: address?.district || '',
              province: address?.province || '',
              zipcode: address?.zipcode || '',
            },
            amount: this.package_detail?.price || 0,
          }
        }
      })
    },
    checkTel(telInvalid, customer_type) {
      if (customer_type === 'personal' && this.customer_verify_status !== 'verified') {
        return !telInvalid
      } else {
        return !this.telState
      }
    },
    changeJuristic() {
      this.form.first_name = ''
    }
  },
  watch: {
    'form.juristic_type'(val) {
      if (val === 'juristic') {
        this.order_summary.include_withholding_tax = this.form.include_withholding_tax ? 3 : 0
        this.order_summary.total_price = this.totalPrice(this.package_detail.price, this.package_detail?.price_discount, this.order_summary.vat, this.order_summary.include_withholding_tax)
      } else {
        this.order_summary.include_withholding_tax = 0
        this.order_summary.total_price = this.totalPrice(this.package_detail.price, this.package_detail?.price_discount, this.order_summary.vat, 0)
      }
    },
    'form.include_withholding_tax'(val) {
      if (this.form.juristic_type === 'juristic' && val) {
        this.order_summary.include_withholding_tax = this.countVat(this.package_detail.price, this.package_detail?.price_discount, 3)
        this.order_summary.total_price = this.totalPrice(this.package_detail.price, this.package_detail?.price_discount, this.order_summary.vat, this.order_summary.include_withholding_tax)
      } else {
        this.order_summary.include_withholding_tax = 0
        this.order_summary.total_price = this.totalPrice(this.package_detail.price, this.package_detail?.price_discount, this.order_summary.vat, 0)
      }
    },
    'is_upgrade_url'(newVal) {
      if (!newVal) {
        this.order_summary.upgrade_detail = null
      }
    }
  }
}
</script>
<style scoped>
.button-wizard {
  min-width: 7rem;
  max-width: 100%;
}

.order-summary-info {
  padding-top: 1rem;
}

.order-total-info {
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 18px;
}

.order-summary-info-sub {
  color: #9F9F9F;
}

.credit-text {
  color: #586A8F;
}


.input-req::v-deep input.th-address-input {
  border: 1px solid var(--danger) !important;
}

.type-promotion {
  font-size: 12px;
  color: #9F9F9F;
}

.box-upgrade-package {
  position: relative;
}

.box-upgrade-package > img {
  width: -webkit-fill-available;
  justify-content: center;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.handle-click-mouse-checkbox::v-deep .custom-control-label {
  cursor: pointer;
}

.handle-click-mouse-checkbox::v-deep .custom-control-label::before {
  top: .3rem;

}

.handle-click-mouse-checkbox::v-deep .custom-control-label::after {
  top: .3rem;

}

.handle-click-mouse {
  transform: rotate(329deg);
  margin-right: 6rem;
  margin-top: -9px;
}

.special-text {
  margin-top: .9rem;
}

.free-text {
  color: red;
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  margin-right: 10px;
  line-height: 1.3rem;
}

</style>