<template>
  <div>
    <b-card>
      <div class="subscription-order-qr-code">
        <h4 class="text-left">{{ $t('subscription.checkout.credit_card.pay_credit') }}</h4>
        <div class="mt-4">
          <b-row>
            <b-col sm="12" md="8" lg="4" xl="3">
              <div class="card-box-secondary-credit">
                <div>
                  <b-row>
                    <b-col class="text-right">{{ $t('subscription.checkout.qr_code.payment_method') }} :</b-col>
                    <b-col class="text-left">{{ $t('subscription.checkout.credit_card.pay_credit') }}</b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col align-self="end">
              <div class="text-right">
                <b-button variant="danger" class="btn-cancel" :class="{'mt-3 w-100 mb-3': innerWidth <= mobileWidth}"
                          @click="cancelSubscription()"
                          v-if="form.invoice_status === 'unpaid'"
                          :disabled="is_cancel_loading">
                  {{ $t('subscription.checkout.cancel_subscription') }}
                </b-button>
              </div>
              <b-row :class="{'justify-content-start': innerWidth <= mobileWidth}" class="justify-content-end mt-2">
                <b-col sm="12" md="12" lg="5" xl="4">
                  <div :class="{'justify-content-start mb-2': innerWidth <= mobileWidth}"
                       class="justify-content-end d-flex">
                    <div class="mr-3">{{ form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
                      $t('invoice.type_receipt') : $t('invoice.type_invoice') }}
                    </div>
                    <div class="flex-middle" :class="form.is_juristic ? 'type-box  company': ' type-box personal'">
                      {{
                      form.is_juristic ? $t('subscription.checkout.juristic') : $t('subscription.checkout.personal')
                      }}
                    </div>
                  </div>
                </b-col>
                <b-col sm="12" md="12" lg="3" xl="2">
                  <div class="d-flex ml-auto justify-content-end">
                    <div class="mr-3">{{ $t('subscription.checkout.status') }}</div>
                    <b-badge pill class="px-2 badge status-box flex-middle"
                             :class="mapStatus(form.invoice_status)">
                      {{ filterValue(optionStatus, form.invoice_status) }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div v-if="form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-approved'"
             class="qr-code-box-success">
          <div class="m-5">
            <div>
              <img src="@/assets/images/icon/check-mark.svg" alt="check-mark" width="120" height="120">
            </div>
            <div class="text-success text-bold mt-3">
              {{ $t('subscription.checkout.qr_code.payment_success') }}
            </div>
            <div class="text-content-black mt-2 mb-5">
              {{ $t('subscription.checkout.qr_code.check_payment_detail') }}
            </div>
          </div>
        </div>
        <div v-else>
          <hr/>
          <div v-if="!webPaymentUrl" class="text-left mt-3 text-danger"><sup>*</sup>{{
            $t('subscription.checkout.credit_card.select_payment_method') }}
          </div>
          <b-row>
            <b-col sm="12" md="4" lg="4" xl="4" class="bank-card m-3"
                   v-for="credit_card in payment_2c2p_list"
                   :key="credit_card._id"
                   :class="{'bank-card-active': account_2c2p_id === credit_card.account_id}"
                   @click="selectBankAccount(credit_card.account_id, '2c2p', credit_card.account_no)">
              <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                <div v-if="credit_card.account_no === '01'">
                  <h4>
                    Credit / Debit Card
                  </h4>
                  <div>
                    <b-row class="justify-content-center text-center">
                      <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                        <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                          <img :src="icon" alt="bank-logo">
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div v-if="credit_card.account_no === '02'">
                  <h4>
                    E-wallet / Digital Payment
                  </h4>
                  <div>
                    <b-row class="justify-content-center text-center">
                      <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                        <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                          <img :src="icon" alt="bank-logo">
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>

              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4 mb-4" v-if="webPaymentUrl">
          <iframe :src="webPaymentUrl"
                  style="width:100%;height:60vh;border-radius: 14px;border: 1px solid #f3f3f3;"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  scrolling="auto"/>
        </div>
        <h4 class="text-left"> {{ form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
          $t('invoice.receipt'): $t('subscription.checkout.qr_code.invoice') }}</h4>
        <div class="mt-3">
          <a v-if="is_android" :href="form.pdf_url" target="_blank">{{ form.pdf_url }}</a>
          <iframe :src="form.pdf_url"
                  style="width:100%;height:50vh;border:2px solid #FFFFFF;border-radius: 14px;margin-top: .5rem"
                  scrolling="auto"
                  v-else/>
        </div>
        <div class="text-right mt-4">
          <b-button v-if="checkPermission('list', 'action', 'all-invoices')" class="button-wizard button-all-invoice"
                    variant="outline" @click="toAllInvoice">
            {{ $t('subscription.checkout.qr_code.all_invoice') }}
          </b-button>
        </div>
      </div>
    </b-card>
    <div>
      <b-modal id="show-qrcode-cancel" size="md" hide-footer no-close-on-backdrop no-close-on-esc centered>
        <template #modal-header="{ close }">
          <div></div>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp()"/>
        </template>
        <div class="mt-0 mb-4 ml-4 mr-4">
          <div class="text-center">
            <h4 class="text-danger">{{ $t('subscription.checkout.reason_for_cancelled') }}</h4>
            <div>
              {{ $t('subscription.checkout.more_then_selected') }}
            </div>
          </div>
          <div class="p-4">
            <b-form-group
                class="ml-5"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-for="option in cancel_reasons_list"
                  v-model="cancel.cancel_reasons"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
              >
                {{ $t(option.text) }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div>
            <b-button class="w-100 " variant="primary"
                      :disabled="cancel.cancel_reasons.length <= 0 || is_cancel_loading"
                      @click="createCancelSubscription">
              {{ $t('common.confirm') }}
              <b-spinner small v-if="is_cancel_loading" class="mt-1"/>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import subscriptionApi from "@/repository/subscriptionApi";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import OverlayPage from "@/components/common/overlayPage.vue";
import alert from "@/common/alert";
import {mapState} from "vuex";
import authApi from "@/repository/authApi";
import settingApi from "@/repository/settingApi";
import functions from "@/common/functions";

export default {
  name: "credit",
  components: {OverlayPage},
  props: ['account_id'],
  data() {
    return {
      is_android: false,
      form: {
        slips: '',
        pdf_url: '',
        bank_account_id: '',
        is_juristic: '',
        invoice_status: '',
        grand_total: 0,
        reference1: '',
        reference2: '',
        transaction_date: '',
      },
      loading: false,
      is_loading_qrcode: false,
      optionStatus: masterData?.invoiceStatus,
      countdown: '00:00',
      timer: 0,
      time: 0,
      is_payment_success: false,
      is_cancel_loading: false,
      cancel_reasons_list: [
        {text: 'subscription.checkout.need_to_modify_order', value: 0},
        {text: 'subscription.checkout.need_to_changed_package', value: 1},
        {text: 'subscription.checkout.other_or_change_your_mind', value: 2},
      ],
      cancel: {
        cancel_reasons: []
      },
      subscription_id: null,
      account_2c2p_id: null,
      webPaymentUrl: '',
      payment_2c2p_list: [],
      invoice_id: ''
    }
  },
  computed: {
    ...mapState({
      confirm_payment: state => state.subscription.confirm_payment,
      innerWidth: state => state.theme.width_inner,
      time_income: state => state.subscription.time,
      mobileWidth: state => state.theme.mobile_width
    })
  },
  mounted() {
    this.subscription_id = this.$route.query.sub_id
    this.account_2c2p_id = this.account_id
    if (this.subscription_id) {
      if (_.isNil(this.account_2c2p_id)) {
        this.getCreditPayment2c2pList()
      }
      this.getSubscriptionByID()
    }
  },
  methods: {
    getSubscriptionByID() {
      this.loading = true
      this.is_loading_qrcode = true
      subscriptionApi.getSubscriptionById(this.subscription_id).then(resp => {
        if (!!resp) {
          let plan = _.get(resp, 'plan', {})
          this.form = {
            ...this.form,
            slips: resp?.slips,
            pdf_url: resp?.pdf_url ? resp?.pdf_url + '#zoom=44' : resp?.invoice_url + '#zoom=44',
            is_juristic: resp?.customer?.is_juristic,
            invoice_status: resp?.invoice_status,
            grand_total: this.changeFormatTotal(plan?.grand_total, plan?.currency_format),
          }
          if (/Android/i.test(navigator.userAgent)) {
            this.is_android = true
          } else {
            this.is_android = false
          }
        }
        return resp
      }).then(resp => {
        if (resp?.invoice_status === 'unpaid' || resp?.invoice_status === 'pre-credit-approved' && resp?.payment_method === '2c2p' && this.account_2c2p_id) {
          this.invoice_id = resp?.invoice_id
          if (this.account_2c2p_id) {
            this.get2c2pPaymentDetail(resp?.invoice_id, this.account_2c2p_id)
          }
        }
      }).catch(err => {
        this.is_loading_qrcode = false
      }).finally(() => {
        this.loading = false
      })
    },

    mapStatus(status) {
      return functionsCommon.colorButtonStatusByText(status);
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    toAllInvoice() {
      this.$router.push('/Invoice').catch(() => {
      })
    },
    changeFormatTotal(total = 0, currency = '฿') {
      return functionsCommon.addCurrencyToPrice(total, currency)
    },
    countdownQrcodeTime() {
      let interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(interval)
          this.countdown = '00:00'
        } else {
          const minutes = Math.floor(this.timer / 60);
          const seconds = this.timer % 60;
          this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          this.is_loading_qrcode = false
          this.timer--
        }
      }, 1000)
    },
    resendQrCode() {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscriptions.confirm_resend_qr'),
        html: this.$t('subscriptions.confirm_resend_qr_content'),
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
      }).then(result => {
        if (result.isConfirmed) {
          this.timer = 0
          this.is_loading_qrcode = true
          this.generateQrcode()
        }
      })
    },
    checkInQuiry() {
      subscriptionApi.checkInQuiryQrcode(this.subscription_id).then(resp => {
        if (!!resp) {
          this.getSubscriptionByID()
        }
      }).catch(err => {
        alert.fail(this, err?.response?.data?.message)
      })
    },
    cancelSubscription() {
      this.$bvModal.show('show-qrcode-cancel')
    },
    closePopUp() {
      this.$bvModal.hide(`show-qrcode-cancel`)
      this.cancel.cancel_reasons = []
    },
    createCancelSubscription() {
      const map_data = {
        id: this.subscription_id,
        cancel_reasons: this.cancel.cancel_reasons
      }
      this.is_cancel_loading = true
      subscriptionApi.cancelSubscriptionById(map_data).then(resp => {
        if (!!resp) {
          this.$router.push('/Invoice').catch(() => {
          })
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.cancelFailed(this, err?.response?.data?.message)
        }
      }).finally(() => {
        this.is_cancel_loading = false
      })
    },
    get2c2pPaymentDetail(invoice_id, payment_id) {
      const data = {
        invoice_no: invoice_id,
        account_2c2p_id: payment_id
      }
      this.webPaymentUrl = null
      settingApi.getDetail2c2pPayment(data).then(resp => {
        this.webPaymentUrl = resp?.webPaymentUrl
      }).catch(err => {
        throw err
      })
    },
    getCreditPayment2c2pList() {
      settingApi.getPayment2c2pList().then(resp => {
        this.payment_2c2p_list = _.filter(resp?.data, item => {
          return item?.status === 1
        })
      }).catch(err => {
        throw err
      })
    },
    selectBankAccount(data, type, payment_type) {
      const confirm_text = payment_type === '01' ? this.$t('subscription.checkout.credit_card.confirm_credit_card') : payment_type === '02' ? this.$t('subscription.checkout.credit_card.confirm_e_wallet') : this.$t('subscription.checkout.credit_card.confirm_your_credit')
      if (data !== this.account_2c2p_id) {
        this.$swal.fire({
          icon: 'warning',
          text: confirm_text,
          showCancelButton: true,
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.confirm'),
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.account_2c2p_id = data
            this.get2c2pPaymentDetail(this.invoice_id, data)
          }
        })

      }
    },
    getCreditProfile() {
      authApi.getCustomerProfile(true).then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  },
  watch: {
    'time_income'(newTime) {
      if (newTime && this.subscription_id === this.confirm_payment.subscription_id) {
        this.webPaymentUrl = null
        this.getSubscriptionByID()
        this.getCreditProfile()
      }
    },
    '$i18n.locale'(val) {
      if (val) {
        this.getSubscriptionByID()
      }
    },
  }
}
</script>
<style scoped>
.card-box-secondary-credit {
  border-radius: 1.25rem;
  background: #F0F0F0;
  padding: 2rem;
  justify-content: center;
  min-height: 84px;
}

.type-box.personal {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box.company {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.qr-code-box-success {
  padding: 1rem !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  margin: 2rem 0 2rem 0;
}

.qr-code-box {
  padding: 0 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  border: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.type-box.personal {
  background: #C5EDFF;
  border: 1px solid #3CADDE;
  color: #2F2E2E;
  text-align: center;
}

.type-box.company {
  background: #FFE1C5;
  border: 1px solid #F49300;
  color: #2F2E2E;
  text-align: center;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.transaction-ref {
  margin-right: 25%;
  margin-left: 25%;
}

.subscription-order-qr-code {
  padding: 1rem;
}

.text-content-black {
  color: #2F2E2E;
}

.text-content-gray {
  color: #9F9F9F;
}

.disable-qrcode {
  opacity: .2;
}

.button-all-invoice {
  padding-left: 4%;
  padding-right: 4%;
}

.bank-card {
  background: #FFF;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10);
  padding: 1rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  //min-height: 125px;
  width: 100%;
}

.bank-img-empty {
  background: #000000;
  border-radius: 10px;
}

.credit-card-img img {
  position: relative;
  width: auto;
  height: 1rem;
  border-radius: 10px;
  margin: .1rem;
  justify-content: center;
}

.bank-card-active {
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 1px 20px -12px var(--primary-color)
}

@media (max-width: 768px) {
  .subscription-order-qr-code {
    padding: 0;
    font-size: 12px !important;
  }

  .qr-code-box {
    font-size: 12px !important;
  }

  .transaction-ref {
    margin-right: 3%;
    margin-left: 3%;
  }
}

</style>