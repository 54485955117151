<template>
  <div>
    <topic-component :topic="$t('SideBarItems.subscriptions.text')"/>
    <div id="form-subscription">
      <form-wizard
          ref="props"
          step-size="xs"
          class="mt-3"
          :hide-buttons="true"
          :start-index="0"
          :title="null"
          :subtitle="null"
          :step-size="'xs'"
      >
        <template v-slot:step="props">
          <WizardStep
              :tab="props.tab"
              :transition="props.transition"
              :index="props.index"
          >
          </WizardStep>
        </template>
        <tab-content :title="$t('subscription.checkout.payment_detail')">
          <payment-detail v-if="is_active === 0" @set-active-step="setStep"/>
        </tab-content>
        <tab-content :title="$t('subscription.checkout.payment_method')">
          <payment-method v-if="is_active === 1" @set-active-step="setStep"/>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent.vue";
import {FormWizard, TabContent, WizardStep} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import PaymentDetail from "@/views/subscriptions/checkout/paymentDetail.vue";
import PaymentMethod from "@/views/subscriptions/checkout/paymentMethod.vue";
import functionsCommon from "@/common/functions";

export default {
  name: 'checkout',
  components: {
    PaymentMethod,
    PaymentDetail,
    TopicComponent,
    FormWizard,
    TabContent,
    WizardStep,
  },
  data() {
    return {
      event_data: {},
      is_active: 0,
      is_payment: ''
    }
  },
  mounted() {
    this.is_active = JSON.parse(sessionStorage.getItem('is_active')) || 0
    this.is_payment = sessionStorage.getItem('is_payment')
    this.$refs.props.changeTab(0, this.is_active)
    this.$refs.props.progressBarStyle.width = JSON.parse(sessionStorage.getItem('_order'))?.set_progressbar || '50%'
  },
  destroyed() {
    sessionStorage.removeItem('is_active')
    sessionStorage.removeItem('is_payment')
    sessionStorage.removeItem('_order')
    sessionStorage.removeItem('_pack')
    this.removeCookiePackageId('P_ID')
  },
  methods: {
    setStep(event) {
      if (event.action === 'next') {
        this.$refs.props.nextTab()
        this.event_data = {
          ...event.data,
          set_progressbar: '100%'
        }
        sessionStorage.setItem('_order', JSON.stringify(this.event_data))
        sessionStorage.setItem('is_payment', 'payment')
        this.$refs.props.progressBarStyle.width = '100%'
      } else {
        if (event.page_index === 1) {
          this.$router.back()
        } else {
          this.$refs.props.prevTab()
        }
        this.event_data = {
          ...event.data,
          set_progressbar: '50%'
        }
        sessionStorage.setItem('_order', JSON.stringify(this.event_data))
        this.$refs.props.progressBarStyle.width = '50%'
      }
      this.is_active = this.$refs.props.activeTabIndex
      sessionStorage.setItem('is_active', this.is_active)
    },
    removeCookiePackageId(name) {
      return functionsCommon.deleteCookie(name)
    }
  },
  watch: {
    'is_active'(val) {
      if (val === 0) {
        sessionStorage.removeItem('is_payment')
      }
    }
  }
}
</script>

<style scoped lang="scss">
#form-subscription::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #fff !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    color: #FFFFFF !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: #AAAAAA !important;
    width: 50% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle.active {
    color: #2F2E2E !important;
  }

  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 20px 0 10px !important;
    }
    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }
    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }
    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }

}
</style>